import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useMenuButtonState } from "../contexts"
import scrollTo from "gatsby-plugin-smoothscroll"

const LinkGroup = ({ isMobileNav = false }) => {
  const { toggleMenu } = useMenuButtonState()

  const handleClick = idSelector => {
    scrollTo(idSelector)

    if (isMobileNav) {
      toggleMenu()
    }

    if (idSelector === "#top" && !isMobileNav) {
      window.location.reload()
    }
  }

  return (
    <LinkGroupContainer>
      <button onClick={() => handleClick("#top")} aria-label="scroll to home">
        HOME
      </button>
      <button
        onClick={() => handleClick("#about")}
        aria-label="scroll to about"
      >
        ABOUT
      </button>
      <button onClick={() => handleClick("#work")} aria-label="scroll to work">
        WORK
      </button>
      <button
        onClick={() => handleClick("#contact")}
        aria-label="scroll to contact"
      >
        CONTACT
      </button>
    </LinkGroupContainer>
  )
}

LinkGroup.propTypes = {
  isMobileNav: PropTypes.bool,
}

export default LinkGroup

const LinkGroupContainer = styled.nav`
  button {
    color: white; // ToDo

    transition: color 0.3s ease-in;

    /* underline animation */
    &::after {
      display: block;
      background: #66fcf1; // ToDo
      width: 0%;
      height: 1px;
      content: "";
      transition: width 0.3s ease-in;
    }

    &:hover {
      color: #66fcf1; // ToDo

      &::after {
        width: 100%;
      }
    }

    &:focus {
      outline: none; // ToDo
    }
  }
`
