import React from "react"
import styled from "styled-components"
import { useScrollAnimation } from "../hooks"
import { media } from "../utilities"
import ContentContainer from "../components-for-contents/ContentContainer"
import CodeEditor from "../components-for-contents/CodeEditor"

const Work = () => {
  const [ref, showAnimation] = useScrollAnimation()

  return (
    <WorkWrapper id="work" showAnimation={showAnimation}>
      <svg
        className="svg-shape"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 25"
        preserveAspectRatio="none"
      >
        <g fill="#0b0c10">
          <path d="M100,0c0,0-18.9,18.9-50,14C18,9,0,25,0,25L0,0L100,0z" />
        </g>
      </svg>

      <ContentContainer className="work-container">
        <div className="work-text">
          <p ref={ref}>
            We work quickly and carefully to produce high quality custom
            designed websites and web applications.
          </p>
        </div>
        <div className="work-code-editor">
          <CodeEditor />
        </div>
      </ContentContainer>
    </WorkWrapper>
  )
}

export default Work

const WorkWrapper = styled.div`
  /* for svg-shape */
  position: relative;

  background: #1f2833;

  .svg-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* set height to pixels if you want angle to change with screen width */
    height: 10vw; /* or 100px */
  }

  .work-container {
    display: block;
    padding-top: 5rem;

    /* greater than 768px (medium) */
    ${media.md} {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 750px;
      padding-top: 7.25rem;
    }

    .work-text {
      transition: opacity 0.7s ease, transform 0.7s ease; // ToDo
      opacity: ${props => (props.showAnimation ? 1 : 0)};
      transform: ${props =>
        props.showAnimation ? "translateY(0)" : "translateY(1.45rem)"};

      width: 100%;

      /* greater than 768px (medium) */
      ${media.md} {
        order: 2;

        width: 50%;
        padding-left: 1.45rem;
      }

      p {
        margin-bottom: 0;
      }
    }

    .work-code-editor {
      width: 100%;
      /* maximum size is 400px */
      max-width: 400px;
      margin: 0 auto;
      margin-top: 4.35rem;

      /* greater than 768px (medium) */
      ${media.md} {
        order: 1;

        width: 50%;
        margin-top: 0;
      }
    }
  }
`
