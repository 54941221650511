import React from "react"
import styled from "styled-components"
import { media } from "../utilities"
import MobileNav from "./MobileNav"
import MenuButton from "./MenuButton"
import YamamotoCreativeLogoSvg from "./YamamotoCreativeLogoSvg"
import LinkGroup from "./LinkGroup"

const Header = () => {
  return (
    <HeaderWrapper>
      <div className="header-container">
        <MobileNav />
        <MenuButton />

        <button className="logo" onClick={() => window.location.reload()}>
          <YamamotoCreativeLogoSvg />
        </button>
        <div className="link-group-wrapper">
          <LinkGroup />
        </div>
      </div>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;

  z-index: 80; // ToDo

  background: transparent; // ToDo
  width: 100%;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent; // ToDo
    height: 50px; // ToDo
    padding: 0 1.45rem;

    /* maximum content size is 992px */
    max-width: 992px;
    margin: 0 auto;

    /* for MenuButton */
    position: relative;

    /* greater than 768px (medium) */
    ${media.md} {
      justify-content: space-between;
    }

    .logo {
      display: none;

      /* greater than 768px (medium) */
      ${media.md} {
        /* show logo */
        display: flex;
        align-items: center;

        > * {
          pointer-events: auto;
        }
      }

      &:focus {
        outline: none; // ToDo
      }
    }

    .link-group-wrapper {
      display: none;

      /* greater than 768px (medium) */
      ${media.md} {
        /* show link-group-wrapper */
        display: block;
        width: 40%;

        nav {
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            font-size: 0.85rem; // ToDo
          }
        }
      }
    }
  }
`
