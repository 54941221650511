import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useShowButton } from "../hooks"

const ScrollToTopButton = () => {
  const showButton = useShowButton()

  return (
    <ScrollToTopButtonWrapper showButton={showButton}>
      <button onClick={() => scrollTo("#top")} aria-label="scroll to the top" />
    </ScrollToTopButtonWrapper>
  )
}

export default ScrollToTopButton

const ScrollToTopButtonWrapper = styled.div`
  position: fixed;
  right: 5%;
  bottom: 30px; /* (Footer height: 80px - button height: 20px) / 2 = 30px */ // ToDo

  z-index: 70; // ToDo

  transition: opacity 0.3s ease-in, transform 0.3s ease-in; // ToDo
  opacity: ${props => (props.showButton ? 1 : 0)};
  transform: ${props => (props.showButton ? "scale(1)" : "scale(0.8)")};

  button {
    /* triangle up */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #66fcf1;
    opacity: 0.5; // ToDo
    transition: opacity 0.3s ease-in, transform 0.3s ease-in; // ToDo

    &:hover {
      opacity: 1; // ToDo
      transform: rotate(360deg); // ToDo
    }

    &:focus {
      outline: none; // ToDo
    }
  }
`
