import React from "react"
import styled from "styled-components"

const MobileSvg = () => {
  return (
    <MobileSvgWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 350">
      <title>Smartphone</title>
      <defs>
        <linearGradient
          id="mobile-svg-gradient"
          x1="0%"
          y1="0%"
          x2="70%"
          y2="-20%"
        >
          <stop offset="10%" stopColor="#00dfd8" />
          <stop offset="50%" stopColor="#7928ca" />
          <stop offset="90%" stopColor="#ff0080" />
        </linearGradient>
      </defs>

      <g id="mobile">
        <path
          className="st0"
          d="M377.3,57.7c-11.8-6.8-31-6.8-42.8,0l-315.3,182c-11.8,6.8-11.8,17.9,0,24.7l116.4,67.2c11.8,6.8,31,6.8,42.8,0l315.3-182c11.8-6.8,11.8-17.9,0-24.7L377.3,57.7z"
        />
        <polygon
          className="st0"
          points="10.5,229.1 10.5,251.3 502.4,136.5 502.4,114.2"
        />
        <path
          className="st1"
          d="M377.3,35.5c-11.8-6.8-31-6.8-42.8,0l-315.3,182c-11.8,6.8-11.8,17.9,0,24.7l116.4,67.2c11.8,6.8,31,6.8,42.8,0l315.3-182c11.8-6.8,11.8-17.9,0-24.7L377.3,35.5z"
        />
        <path
          className="st2"
          d="M371,42.5c-11.2-6.4-29.5-6.4-40.6,0L30.9,215.4c-11.2,6.4-11.2,17,0,23.5l110.5,63.8c11.2,6.4,29.5,6.4,40.6,0l299.5-172.9c11.2-6.4,11.2-17,0-23.5L371,42.5z"
        />
        <path
          className="st3"
          d="M87.7,297.1l-18.6-10.5c-2.4-1.3-4.3-4.3-4.3-6.7l0,0c0-2.3,1.9-3.1,4.3-1.8l18.6,10.5c2.4,1.3,4.3,4.3,4.3,6.7l0,0C92,297.6,90.1,298.4,87.7,297.1z"
        />
        <path
          className="st4"
          d="M446.8,163.7l24.9-14.4c1-0.6,1.8-0.2,1.8,0.8v1.8c0,1-0.8,2.2-1.8,2.8l-24.9,14.4c-1,0.6-1.8,0.2-1.8-0.8v-1.8C445,165.6,445.8,164.3,446.8,163.7z"
        />
        <path
          className="st4"
          d="M412.3,183.5l24.9-14.4c1-0.6,1.8-0.2,1.8,0.8v1.8c0,1-0.8,2.2-1.8,2.8l-24.9,14.4c-1,0.6-1.8,0.2-1.8-0.8v-1.8C410.5,185.3,411.3,184.1,412.3,183.5z"
        />
      </g>

      <g id="display">
        <path
          className="st5"
          d="M371,17.8c-11.2-6.4-29.5-6.4-40.6,0L30.9,190.7c-11.2,6.4-11.2,17,0,23.5L141.4,278c11.2,6.4,29.5,6.4,40.6,0l299.5-172.9c11.2-6.4,11.2-17,0-23.5L371,17.8z"
        />
        <path
          className="st6"
          d="M57.4,175.6l-26.6,15.4c0,0-7.6,4.4-7.5,10.5c0.1,6.1,6.3,11.2,6.3,11.2l27.9,16.1V175.6z"
        />
        <path
          className="st6"
          d="M296.9,211.4l46.9-27.1V13.7c-7.3,1-11.5,3.1-11.5,3.1l-35.4,20.5V211.4z"
        />
        <path
          className="st6"
          d="M361.1,174.3l54.3-31.4V43.7l-46.6-26.9c0,0-2.8-1.6-7.7-2.7V174.3z"
        />
        <path
          className="st6"
          d="M148.8,122.8l-44.4,25.7v107.4l38.3,22.1c0,0,2,1.4,6.1,2.7V122.8z"
        />
        <path
          className="st6"
          d="M489.4,92.7c-0.6-6.7-7.5-10.5-7.5-10.5l-46.7-27v76.4l48-27.7C483.1,103.9,490,99.4,489.4,92.7z"
        />
        <path
          className="st6"
          d="M240.1,70.1l-74.1,42.8v169.4c10.6-0.7,15.5-4.3,15.5-4.3l58.5-33.8V70.1z"
        />
      </g>
    </MobileSvgWrapper>
  )
}

export default MobileSvg

const MobileSvgWrapper = styled.svg`
  width: 512px;
  height: 350px;

  .st0 {
    fill: #efefef;
  }
  .st1 {
    fill: white;
  }
  .st2 {
    fill: url("#mobile-svg-gradient");
  }
  .st3 {
    fill: #00dfd8;
  }
  .st4 {
    fill: #ff0080;
  }
  .st5 {
    fill: none;
    stroke: white;
    stroke-width: 2.4687;
    stroke-miterlimit: 10;
    opacity: 0.2;
  }
  .st6 {
    fill: white;
    opacity: 0.2;
  }

  #display {
    animation: animate-display 2s ease infinite alternate;
  }

  @keyframes animate-display {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(0, -50px);
    }
  }
`
