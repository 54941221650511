import React, { useState } from "react"
import styled from "styled-components"

const CopyEmailAddressButton = () => {
  const [isClicked, setClicked] = useState(false)

  const copyToClipboard = async () => {
    try {
      if (!isClicked) {
        setClicked(true)

        const emailAddress = "info@yamamotocreative.com"
        await navigator.clipboard.writeText(emailAddress)

        setTimeout(() => {
          setClicked(false)
        }, 5000)
      }
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }

  return (
    <CopyEmailAddressButtonWrapper isClicked={isClicked}>
      <button
        onClick={() => copyToClipboard()}
        disabled={isClicked}
        aria-label="copy email address to clipboard"
      >
        <small>{isClicked ? "Copied" : "Copy"}</small>
      </button>
    </CopyEmailAddressButtonWrapper>
  )
}

export default CopyEmailAddressButton

const CopyEmailAddressButtonWrapper = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    min-width: 70px;
    border: 1px solid white;
    border-radius: 18px;

    cursor: ${props => (props.isClicked ? "not-allowed" : "pointer")};

    small {
      color: white;
    }

    &:disabled {
      border: 1px solid #c5c6c7;

      small {
        color: #c5c6c7;
      }
    }
  }
`
