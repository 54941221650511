import React, { useEffect } from "react"
import styled from "styled-components"
import { useToggle, useScrollAnimation } from "../hooks"
import Typewriter from "./Typewriter"

const TypingAnimation = () => {
  const [ref, showAnimation] = useScrollAnimation()

  // line 1
  const { isToggled: isAToggled, toggle: toggleA } = useToggle(false)
  const { isToggled: isBToggled, toggle: toggleB } = useToggle(false)
  const { isToggled: isCToggled, toggle: toggleC } = useToggle(false)
  const { isToggled: isDToggled, toggle: toggleD } = useToggle(false)
  const { isToggled: isEToggled, toggle: toggleE } = useToggle(false)
  const { isToggled: isFToggled, toggle: toggleF } = useToggle(false)

  // line 2
  const { isToggled: isGToggled, toggle: toggleG } = useToggle(false)
  const { isToggled: isHToggled, toggle: toggleH } = useToggle(false)

  // line 3
  const { isToggled: isIToggled, toggle: toggleI } = useToggle(false)
  const { isToggled: isJToggled, toggle: toggleJ } = useToggle(false)
  const { isToggled: isKToggled, toggle: toggleK } = useToggle(false)

  // line 4
  const { isToggled: isLToggled, toggle: toggleL } = useToggle(false)
  const { isToggled: isMToggled, toggle: toggleM } = useToggle(false)
  const { isToggled: isNToggled, toggle: toggleN } = useToggle(false)
  const { isToggled: isOToggled, toggle: toggleO } = useToggle(false)
  const { isToggled: isPToggled, toggle: toggleP } = useToggle(false)
  const { isToggled: isQToggled, toggle: toggleQ } = useToggle(false)
  const { isToggled: isRToggled, toggle: toggleR } = useToggle(false)

  // line 5
  const { isToggled: isSToggled, toggle: toggleS } = useToggle(false)
  const { isToggled: isTToggled, toggle: toggleT } = useToggle(false)
  const { isToggled: isUToggled, toggle: toggleU } = useToggle(false)

  // line 6
  const { isToggled: isVToggled, toggle: toggleV } = useToggle(false)

  // line 7
  const { isToggled: isWToggled, toggle: toggleW } = useToggle(false)

  // start typing
  useEffect(() => {
    if (showAnimation) {
      toggleA()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnimation])

  return (
    <TypingAnimationWrapper ref={ref}>
      <p>
        <Typewriter
          isSpanOn={isAToggled}
          spanToggle={toggleB}
          spanClassName="blue-color"
          spanText="const "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isBToggled}
          spanToggle={toggleC}
          spanClassName="white-color"
          spanText="Work "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isCToggled}
          spanToggle={toggleD}
          spanClassName="turquoise-color"
          spanText="= "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isDToggled}
          spanToggle={toggleE}
          spanClassName="yellow-color"
          spanText="( ) "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isEToggled}
          spanToggle={toggleF}
          spanClassName="turquoise-color"
          spanText="=> "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isFToggled}
          spanToggle={toggleG}
          spanClassName="yellow-color"
          spanText="{"
        />
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Typewriter
          isSpanOn={isGToggled}
          spanToggle={toggleH}
          spanClassName="pink-color"
          spanText="return "
        />
        <span>&nbsp;</span>
        <Typewriter
          isSpanOn={isHToggled}
          spanToggle={toggleI}
          spanClassName="pink-color"
          spanText="("
        />
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Typewriter
          isSpanOn={isIToggled}
          spanToggle={toggleJ}
          spanClassName="light-gray-color"
          spanText="<"
        />
        <Typewriter
          isSpanOn={isJToggled}
          spanToggle={toggleK}
          spanClassName="turquoise-color"
          spanText="WorkWrapper"
        />
        <Typewriter
          isSpanOn={isKToggled}
          spanToggle={toggleL}
          spanClassName="light-gray-color"
          spanText=">"
        />
      </p>
      <p>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <Typewriter
          isSpanOn={isLToggled}
          spanToggle={toggleM}
          spanClassName="light-gray-color"
          spanText="<"
        />
        <Typewriter
          isSpanOn={isMToggled}
          spanToggle={toggleN}
          spanClassName="blue-color"
          spanText="p"
        />
        <Typewriter
          isSpanOn={isNToggled}
          spanToggle={toggleO}
          spanClassName="light-gray-color"
          spanText=">"
        />
        <Typewriter
          isSpanOn={isOToggled}
          spanToggle={toggleP}
          spanClassName="white-color"
          spanText="Quality"
        />
        <Typewriter
          isSpanOn={isPToggled}
          spanToggle={toggleQ}
          spanClassName="light-gray-color"
          spanText="</"
        />
        <Typewriter
          isSpanOn={isQToggled}
          spanToggle={toggleR}
          spanClassName="blue-color"
          spanText="p"
        />
        <Typewriter
          isSpanOn={isRToggled}
          spanToggle={toggleS}
          spanClassName="light-gray-color"
          spanText=">"
        />
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Typewriter
          isSpanOn={isSToggled}
          spanToggle={toggleT}
          spanClassName="light-gray-color"
          spanText="</"
        />
        <Typewriter
          isSpanOn={isTToggled}
          spanToggle={toggleU}
          spanClassName="turquoise-color"
          spanText="WorkWrapper"
        />
        <Typewriter
          isSpanOn={isUToggled}
          spanToggle={toggleV}
          spanClassName="light-gray-color"
          spanText=">"
        />
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Typewriter
          isSpanOn={isVToggled}
          spanToggle={toggleW}
          spanClassName="pink-color"
          spanText=")"
        />
      </p>
      <p>
        <Typewriter
          isSpanOn={isWToggled}
          spanClassName="yellow-color"
          spanText="}"
        />
      </p>
    </TypingAnimationWrapper>
  )
}

export default TypingAnimation

const TypingAnimationWrapper = styled.div`
  p {
    margin-bottom: 0;
  }

  .blue-color {
    color: #007cf0;
  }

  .white-color {
    color: white;
  }

  .turquoise-color {
    color: #00dfd8;
  }

  .yellow-color {
    color: #f9cb28;
  }

  .pink-color {
    color: #ff0080;
  }

  .light-gray-color {
    color: #c5c6c7;
  }
`
