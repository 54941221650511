import React from "react"
import styled from "styled-components"
import ScrollToTopButton from "./ScrollToTopButton"

const Footer = () => {
  return (
    <FooterWrapper>
      <h6>© {new Date().getFullYear()} YAMAMOTO CREATIVE</h6>

      <ScrollToTopButton />
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent; // ToDo
  width: 100%;
  height: 80px; // ToDo

  h6 {
    color: #c5c6c7; // ToDo
    font-weight: normal; // ToDo
    margin-bottom: 0; // ToDo
  }
`
