import React from "react"
import styled from "styled-components"
import { useMenuButtonState } from "../contexts"
import { media } from "../utilities"

const MenuButton = () => {
  const { isMenuOpen, toggleMenu } = useMenuButtonState()

  return (
    <MenuButtonWrapper isMenuOpen={isMenuOpen}>
      <button onClick={toggleMenu} aria-label="menu">
        <span className="bar-top"></span>
        <span className="bar-middle"></span>
        <span className="bar-bottom"></span>
      </button>
    </MenuButtonWrapper>
  )
}

export default MenuButton

const MenuButtonWrapper = styled.div`
  display: block;

  position: absolute;
  top: 0;
  left: 0;

  width: 30px;
  height: 30px;
  margin: 10px 1.45rem; /* (Header height: 50px - MenuButton height: 30px) / 2 = 10px */ // ToDo
  z-index: 100; // ToDo

  /* greater than 768px (medium) */
  ${media.md} {
    /* remove MenuButtonWrapper */
    display: none;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 30px;
    height: 30px;

    transition: padding 0.2s ease;
    padding: ${props => (props.isMenuOpen ? "3px 0" : "6px 0")};

    &:focus {
      outline: none; // ToDo
    }

    span {
      display: block;
      background: white; // ToDo
      width: 25px;
      height: 3px;
      border-radius: 5px;
    }

    .bar-top {
      transition: width 0.2s ease, transform 0.2s ease;
      width: ${props => (props.isMenuOpen ? "30px" : "25px")};
      transform-origin: left center;
      transform: ${props =>
        props.isMenuOpen
          ? "translateX(4px) rotate(45deg)"
          : "translateX(0) rotate(0deg)"};
    }

    .bar-middle {
      transition: opacity 0.3s ease, transform 0.3s ease;
      opacity: ${props => (props.isMenuOpen ? 0 : 1)};
      transform: ${props =>
        props.isMenuOpen ? "translateX(-10px)" : "translateX(0)"};
    }

    .bar-bottom {
      transition: width 0.2s ease, transform 0.2s ease;
      width: ${props => (props.isMenuOpen ? "30px" : "25px")};
      transform-origin: left center;
      transform: ${props =>
        props.isMenuOpen
          ? "translateX(4px) rotate(-45deg)"
          : "translateX(0) rotate(0deg)"};
    }
  }
`
