import React from "react"
import styled from "styled-components"
import { useScrollAnimation } from "../hooks"
import { media } from "../utilities"
import ContentContainer from "../components-for-contents/ContentContainer"
import EmailAddressSvg from "../components-for-contents/EmailAddressSvg"
import CopyEmailAddressButton from "../components-for-contents/CopyEmailAddressButton"
import MobileSvg from "../components-for-contents/MobileSvg"

const Contact = () => {
  const [ref, showAnimation] = useScrollAnimation()

  return (
    <ContactWrapper id="contact" showAnimation={showAnimation}>
      <ContentContainer className="contact-container">
        <div className="contact-info">
          <div>
            <p ref={ref}>
              Let's talk about your website, web application, or anything else.
              We're happy to answer your questions. Please email us and we'll
              respond as soon as possible.
            </p>
          </div>
          <div>
            <EmailAddressSvg />
          </div>
          <CopyEmailAddressButton />
        </div>
        <div className="contact-svg">
          <MobileSvg />
        </div>
      </ContentContainer>
    </ContactWrapper>
  )
}

export default Contact

const ContactWrapper = styled.div`
  .contact-container {
    display: block;

    /* greater than 768px (medium) */
    ${media.md} {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 700px;
    }

    .contact-info {
      transition: opacity 0.7s ease, transform 0.7s ease; // ToDo
      opacity: ${props => (props.showAnimation ? 1 : 0)};
      transform: ${props =>
        props.showAnimation ? "translateY(0)" : "translateY(1.45rem)"};

      width: 100%;

      svg {
        width: 270px;
        height: 27px;
      }

      button {
        margin-top: 0.725rem;
      }

      /* greater than 480px (small) */
      ${media.sm} {
        svg {
          width: 300px;
          height: 30px;
        }
      }

      /* greater than 768px (medium) */
      ${media.md} {
        width: 65%;
        padding-right: 1.45rem;
      }
    }

    .contact-svg {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      svg {
        width: 270px;
        height: 324px;
      }

      /* greater than 768px (medium) */
      ${media.md} {
        justify-content: flex-end;

        width: 35%;

        svg {
          width: 512px;
          height: 350px;
        }
      }
    }
  }
`
