import React from "react"
import styled from "styled-components"
import { useMenuButtonState } from "../contexts"
import { useScrollFreeze } from "../hooks"
import { media } from "../utilities"
import LinkGroup from "./LinkGroup"

const MobileNav = () => {
  const { isMenuOpen } = useMenuButtonState()
  useScrollFreeze(isMenuOpen)

  return (
    <MobileNavWrapper isMenuOpen={isMenuOpen}>
      <div className="mobile-link-group-wrapper">
        <LinkGroup isMobileNav={true} />
      </div>
    </MobileNavWrapper>
  )
}

export default MobileNav

const MobileNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: #1f2833; // ToDo
  padding-top: 2.25rem; // ToDo
  z-index: 90; // ToDo

  transition: transform 0.3s ease;
  transform: ${props =>
    props.isMenuOpen ? "translateX(0)" : "translateX(-100%)"};

  /* greater than 768px (medium) */
  ${media.md} {
    /* remove MobileNavWrapper */
    display: none;
  }

  .mobile-link-group-wrapper {
    button {
      display: block;
      font-size: 1.38316rem; // ToDo
      line-height: 3.45rem; // ToDo

      /* less than or equal to 375 height for mobile landscape orientation */
      @media only screen and (max-height: 375px) {
        line-height: 2.9rem; // ToDo
      }
    }
  }
`
