import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const Typewriter = ({ isSpanOn, spanToggle, spanClassName, spanText }) => {
  const [substringIndexEnd, setSubstringIndexEnd] = useState(0)
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    // completed displaying all span text
    if (substringIndexEnd === spanText.length) {
      // stop showing the text cursor
      setIsTyping(false)

      if (typeof spanToggle === "function") {
        // start showing the next line
        spanToggle()
      }

      return
    }

    // start typing span text
    if (isSpanOn) {
      setIsTyping(true)

      const timeout = setTimeout(() => {
        setSubstringIndexEnd(prevState => prevState + 1)
      }, 50)

      return () => clearTimeout(timeout)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [substringIndexEnd, isSpanOn])

  return (
    <>
      <span className={spanClassName}>
        {spanText.substring(0, substringIndexEnd)}
      </span>
      <span>{isTyping ? "|" : ""}</span>
    </>
  )
}

Typewriter.propTypes = {
  isSpanOn: PropTypes.bool.isRequired,
  spanToggle: PropTypes.func,
  spanClassName: PropTypes.string.isRequired,
  spanText: PropTypes.string.isRequired,
}

export default Typewriter
