import styled from "styled-components"
import { media } from "../utilities"

const ContentContainer = styled.div`
  /* maximum content size is 992px */
  max-width: 992px;
  margin: 0 auto;

  padding: 2.9rem 1.45rem;

  /* greater than 768px (medium) */
  ${media.md} {
    padding: 4.35rem 1.45rem;
  }
`

export default ContentContainer
