import React from "react"
import styled from "styled-components"
import TypingAnimation from "./TypingAnimation"

const CodeEditor = () => {
  return (
    <CodeEditorWrapper>
      <div className="code-editor-header">
        <span className="dot orange-background"></span>
        <span className="dot yellow-background"></span>
        <span className="dot turquoise-background"></span>
      </div>
      <div className="code-editor-body">
        <div className="code-editor-number">
          <p>1</p>
          <p>2</p>
          <p>3</p>
          <p>4</p>
          <p>5</p>
          <p>6</p>
          <p>7</p>
        </div>
        <div>
          <TypingAnimation />
        </div>
      </div>
    </CodeEditorWrapper>
  )
}

export default CodeEditor

const CodeEditorWrapper = styled.div`
  background: #1c2025;
  border-radius: 4px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);

  .code-editor-header {
    display: flex;
    align-items: center;

    background: #282c34;
    height: 40px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .dot {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .orange-background {
      background: #ff4d4d;
      margin-left: 14px;
    }

    .yellow-background {
      background: #f9cb28;
      margin-left: 7px;
    }

    .turquoise-background {
      background: #00dfd8;
      margin-left: 7px;
    }
  }

  .code-editor-body {
    display: flex;
    align-items: flex-start;

    padding: 0.725rem 0.725rem 1.45rem 0.725rem;

    .code-editor-number {
      margin-right: 0.725rem;

      p {
        color: #c5c6c7;
        margin-bottom: 0;
      }
    }
  }
`
