import React from "react"
import PropTypes from "prop-types"

const EmailAddressSvg = ({
  width = "300px", // or 512px the same as viewBox
  height = "30px", // or 45px the same as viewBox
  fill = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 45"
      width={width}
      height={height}
    >
      <title>Email address</title>
      <g fill={fill}>
        <path d="M7.6,7c0,1.3-0.9,2.3-2.4,2.3C4,9.2,3.1,8.2,3.1,7c0-1.3,1-2.3,2.3-2.3C6.8,4.6,7.6,5.7,7.6,7z M3.5,33V12.7h3.7V33H3.5z" />
        <path d="M13.3,18.2c0-2.1,0-3.8-0.2-5.5h3.3l0.2,3.4h0.1c1-1.9,3.4-3.8,6.7-3.8c2.8,0,7.2,1.7,7.2,8.7V33H27V21.3c0-3.3-1.2-6-4.7-6c-2.4,0-4.3,1.7-5,3.8c-0.2,0.5-0.3,1.1-0.3,1.7V33h-3.7V18.2z" />
        <path d="M37,33V15.5h-2.9v-2.8H37v-1c0-2.9,0.6-5.5,2.4-7.1c1.4-1.3,3.2-1.9,5-1.9c1.3,0,2.4,0.3,3.2,0.6L47,6.2c-0.5-0.3-1.3-0.5-2.4-0.5c-3.2,0-3.9,2.8-3.9,5.9v1.1h4.9v2.8h-4.9V33H37z" />
        <path d="M66.8,22.7c0,7.5-5.2,10.8-10.1,10.8c-5.5,0-9.7-4-9.7-10.5c0-6.8,4.5-10.8,10.1-10.8C62.8,12.2,66.8,16.5,66.8,22.7zM50.6,22.9c0,4.5,2.6,7.8,6.2,7.8c3.5,0,6.2-3.3,6.2-7.9c0-3.4-1.7-7.8-6.1-7.8C52.5,15,50.6,19,50.6,22.9z" />
        <path d="M90.4,35.7c-2.3,1.2-4.7,1.7-7.5,1.7c-6.9,0-12.7-5-12.7-13.1c0-8.7,6-16.1,15.3-16.1c7.3,0,12.2,5.1,12.2,12.1c0,6.3-3.5,10-7.5,10c-1.7,0-3.2-1.1-3.1-3.7H87c-1.5,2.5-3.4,3.7-5.9,3.7c-2.4,0-4.5-2-4.5-5.3c0-5.2,4.1-10,10-10c1.8,0,3.4,0.4,4.5,0.9l-1.4,7.6c-0.6,3.2-0.1,4.7,1.3,4.7c2.1,0,4.5-2.8,4.5-7.7c0-6.1-3.7-10.5-10.2-10.5c-6.8,0-12.6,5.4-12.6,13.9c0,7,4.6,11.3,10.8,11.3c2.4,0,4.6-0.5,6.3-1.5L90.4,35.7z M88.1,17.5c-0.4-0.1-1.1-0.3-2.1-0.3c-3.7,0-6.7,3.5-6.7,7.6c0,1.8,0.9,3.2,2.8,3.2c2.4,0,4.7-3.1,5.1-5.7L88.1,17.5z" />
        <path d="M103.7,12.7l4.5,12c0.5,1.3,1,2.9,1.3,4.2h0.1c0.4-1.2,0.8-2.8,1.3-4.2l4-11.9h3.9l-5.5,14.5c-2.6,7-4.5,10.5-7,12.7c-1.8,1.6-3.6,2.2-4.5,2.4l-0.9-3.1c0.9-0.3,2.1-0.9,3.2-1.8c1-0.8,2.3-2.2,3.1-4.1c0.2-0.4,0.3-0.7,0.3-0.9s-0.1-0.5-0.3-1l-7.5-18.7H103.7z" />
        <path d="M133.3,33l-0.3-2.6h-0.1c-1.1,1.6-3.3,3-6.2,3c-4.1,0-6.2-2.9-6.2-5.8c0-4.9,4.4-7.6,12.2-7.6v-0.4c0-1.7-0.5-4.7-4.6-4.7c-1.9,0-3.9,0.6-5.3,1.5l-0.8-2.4c1.7-1.1,4.1-1.8,6.7-1.8c6.2,0,7.7,4.2,7.7,8.3v7.6c0,1.8,0.1,3.5,0.3,4.9H133.3z M132.7,22.6c-4-0.1-8.6,0.6-8.6,4.6c0,2.4,1.6,3.5,3.5,3.5c2.6,0,4.3-1.7,4.9-3.4c0.1-0.4,0.2-0.8,0.2-1.2V22.6z" />
        <path d="M142.2,18.2c0-2.1,0-3.8-0.2-5.5h3.2l0.2,3.3h0.1c1.1-1.9,3-3.7,6.4-3.7c2.8,0,4.9,1.7,5.8,4.1h0.1c0.6-1.1,1.4-2,2.3-2.6c1.2-0.9,2.6-1.4,4.5-1.4c2.7,0,6.7,1.8,6.7,8.8v12h-3.6V21.5c0-3.9-1.4-6.3-4.4-6.3c-2.1,0-3.7,1.6-4.4,3.4c-0.2,0.5-0.3,1.2-0.3,1.8V33H155V20.8c0-3.2-1.4-5.6-4.2-5.6c-2.3,0-4,1.8-4.6,3.7c-0.2,0.5-0.3,1.2-0.3,1.8V33h-3.6V18.2z" />
        <path d="M188.5,33l-0.3-2.6h-0.1c-1.1,1.6-3.3,3-6.2,3c-4.1,0-6.2-2.9-6.2-5.8c0-4.9,4.4-7.6,12.2-7.6v-0.4c0-1.7-0.5-4.7-4.6-4.7c-1.9,0-3.9,0.6-5.3,1.5l-0.8-2.4c1.7-1.1,4.1-1.8,6.7-1.8c6.2,0,7.7,4.2,7.7,8.3v7.6c0,1.8,0.1,3.5,0.3,4.9H188.5z M188,22.6c-4-0.1-8.6,0.6-8.6,4.6c0,2.4,1.6,3.5,3.5,3.5c2.6,0,4.3-1.7,4.9-3.4c0.1-0.4,0.2-0.8,0.2-1.2V22.6z" />
        <path d="M197.5,18.2c0-2.1,0-3.8-0.2-5.5h3.2l0.2,3.3h0.1c1.1-1.9,3-3.7,6.4-3.7c2.8,0,4.9,1.7,5.8,4.1h0.1c0.6-1.1,1.4-2,2.3-2.6c1.2-0.9,2.6-1.4,4.5-1.4c2.7,0,6.7,1.8,6.7,8.8v12h-3.6V21.5c0-3.9-1.4-6.3-4.4-6.3c-2.1,0-3.7,1.6-4.4,3.4c-0.2,0.5-0.3,1.2-0.3,1.8V33h-3.6V20.8c0-3.2-1.4-5.6-4.2-5.6c-2.3,0-4,1.8-4.6,3.7c-0.2,0.5-0.3,1.2-0.3,1.8V33h-3.6V18.2z" />
        <path d="M250.9,22.7c0,7.5-5.2,10.8-10.1,10.8c-5.5,0-9.7-4-9.7-10.5c0-6.8,4.5-10.8,10.1-10.8C247,12.2,250.9,16.5,250.9,22.7zM234.8,22.9c0,4.5,2.6,7.8,6.2,7.8c3.5,0,6.2-3.3,6.2-7.9c0-3.4-1.7-7.8-6.1-7.8C236.7,15,234.8,19,234.8,22.9z" />
        <path d="M260.1,6.8v5.8h5.3v2.8h-5.3v11c0,2.5,0.7,3.9,2.8,3.9c1,0,1.7-0.1,2.1-0.3l0.2,2.8c-0.7,0.3-1.8,0.5-3.3,0.5c-1.7,0-3.1-0.5-4-1.6c-1-1.1-1.4-2.9-1.4-5.3V15.5h-3.2v-2.8h3.2V7.8L260.1,6.8z" />
        <path d="M287.6,22.7c0,7.5-5.2,10.8-10.1,10.8c-5.5,0-9.7-4-9.7-10.5c0-6.8,4.5-10.8,10.1-10.8C283.7,12.2,287.6,16.5,287.6,22.7zM271.5,22.9c0,4.5,2.6,7.8,6.2,7.8c3.5,0,6.2-3.3,6.2-7.9c0-3.4-1.7-7.8-6.1-7.8C273.4,15,271.5,19,271.5,22.9z" />
        <path d="M306.8,32.2c-1,0.5-3.1,1.2-5.8,1.2c-6.1,0-10.1-4.2-10.1-10.4c0-6.3,4.3-10.8,10.9-10.8c2.2,0,4.1,0.5,5.1,1l-0.8,2.9c-0.9-0.5-2.3-1-4.3-1c-4.7,0-7.2,3.4-7.2,7.7c0,4.7,3,7.6,7.1,7.6c2.1,0,3.5-0.5,4.5-1L306.8,32.2z" />
        <path d="M311.1,19c0-2.4,0-4.5-0.2-6.3h3.2l0.1,4h0.2c0.9-2.7,3.2-4.5,5.6-4.5c0.4,0,0.7,0,1.1,0.1v3.5c-0.4-0.1-0.8-0.1-1.3-0.1c-2.6,0-4.5,2-5,4.7c-0.1,0.5-0.2,1.1-0.2,1.7V33h-3.7V19z" />
        <path d="M326.5,23.5c0.1,5,3.3,7.1,7,7.1c2.6,0,4.2-0.5,5.6-1l0.6,2.6c-1.3,0.6-3.5,1.3-6.8,1.3c-6.3,0-10-4.1-10-10.2c0-6.1,3.6-11,9.5-11c6.6,0,8.4,5.8,8.4,9.6c0,0.8-0.1,1.3-0.1,1.7H326.5z M337.3,20.9c0-2.4-1-6-5.1-6c-3.7,0-5.4,3.4-5.7,6H337.3z" />
        <path d="M356.7,33l-0.3-2.6h-0.1c-1.1,1.6-3.3,3-6.2,3c-4.1,0-6.2-2.9-6.2-5.8c0-4.9,4.4-7.6,12.2-7.6v-0.4c0-1.7-0.5-4.7-4.6-4.7c-1.9,0-3.9,0.6-5.3,1.5l-0.8-2.4c1.7-1.1,4.1-1.8,6.7-1.8c6.2,0,7.7,4.2,7.7,8.3v7.6c0,1.8,0.1,3.5,0.3,4.9H356.7z M356.2,22.6c-4-0.1-8.6,0.6-8.6,4.6c0,2.4,1.6,3.5,3.5,3.5c2.6,0,4.3-1.7,4.9-3.4c0.1-0.4,0.2-0.8,0.2-1.2V22.6z" />
        <path d="M370,6.8v5.8h5.3v2.8H370v11c0,2.5,0.7,3.9,2.8,3.9c1,0,1.7-0.1,2.1-0.3l0.2,2.8c-0.7,0.3-1.8,0.5-3.3,0.5c-1.7,0-3.1-0.5-4-1.6c-1-1.1-1.4-2.9-1.4-5.3V15.5h-3.2v-2.8h3.2V7.8L370,6.8z" />
        <path d="M383.6,7c0,1.3-0.9,2.3-2.4,2.3c-1.3,0-2.2-1-2.2-2.3c0-1.3,1-2.3,2.3-2.3C382.7,4.6,383.6,5.7,383.6,7z M379.5,33V12.7h3.7V33H379.5z" />
        <path d="M390.7,12.7l4,11.4c0.7,1.8,1.2,3.5,1.6,5.2h0.1c0.5-1.7,1-3.4,1.7-5.2l3.9-11.4h3.9L398,33h-3.5l-7.7-20.3H390.7z" />
        <path d="M411.1,23.5c0.1,5,3.3,7.1,7,7.1c2.6,0,4.2-0.5,5.6-1l0.6,2.6c-1.3,0.6-3.5,1.3-6.8,1.3c-6.3,0-10-4.1-10-10.2c0-6.1,3.6-11,9.5-11c6.6,0,8.4,5.8,8.4,9.6c0,0.8-0.1,1.3-0.1,1.7H411.1z M422,20.9c0-2.4-1-6-5.1-6c-3.7,0-5.4,3.4-5.7,6H422z" />
        <path d="M428.7,30.8c0-1.6,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.6-2.5,2.6C429.7,33.5,428.7,32.3,428.7,30.8z" />
        <path d="M452.8,32.2c-1,0.5-3.1,1.2-5.8,1.2c-6.1,0-10.1-4.2-10.1-10.4c0-6.3,4.3-10.8,10.9-10.8c2.2,0,4.1,0.5,5.1,1l-0.8,2.9c-0.9-0.5-2.3-1-4.3-1c-4.7,0-7.2,3.4-7.2,7.7c0,4.7,3,7.6,7.1,7.6c2.1,0,3.5-0.5,4.5-1L452.8,32.2z" />
        <path d="M475.3,22.7c0,7.5-5.2,10.8-10.1,10.8c-5.5,0-9.7-4-9.7-10.5c0-6.8,4.5-10.8,10.1-10.8C471.3,12.2,475.3,16.5,475.3,22.7zM459.1,22.9c0,4.5,2.6,7.8,6.2,7.8c3.5,0,6.2-3.3,6.2-7.9c0-3.4-1.7-7.8-6.1-7.8C461,15,459.1,19,459.1,22.9z" />
        <path d="M479.9,18.2c0-2.1,0-3.8-0.2-5.5h3.2l0.2,3.3h0.1c1.1-1.9,3-3.7,6.4-3.7c2.8,0,4.9,1.7,5.8,4.1h0.1c0.6-1.1,1.4-2,2.3-2.6c1.2-0.9,2.6-1.4,4.5-1.4c2.7,0,6.7,1.8,6.7,8.8v12h-3.6V21.5c0-3.9-1.4-6.3-4.4-6.3c-2.1,0-3.7,1.6-4.4,3.4c-0.2,0.5-0.3,1.2-0.3,1.8V33h-3.6V20.8c0-3.2-1.4-5.6-4.2-5.6c-2.3,0-4,1.8-4.6,3.7c-0.2,0.5-0.3,1.2-0.3,1.8V33h-3.6V18.2z" />
      </g>
    </svg>
  )
}

EmailAddressSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default EmailAddressSvg
