import React from "react"
import styled from "styled-components"
import { media } from "../utilities"
import ContentContainer from "../components-for-contents/ContentContainer"

const Home = () => {
  return (
    <HomeWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 25"
        preserveAspectRatio="none"
      >
        <g fill="#0b0c10">
          <path d="M0,0c0,0,18.9,18.9,50,14c32-5,50,11,50,11V0H0z" />
        </g>
      </svg>
      <ContentContainer className="hero-container">
        <h1>
          WEBSITE
          <br />
          APPLICATION
          <br />
          YAMAMOTO CREATIVE
        </h1>
      </ContentContainer>
    </HomeWrapper>
  )
}

export default Home

const HomeWrapper = styled.div`
  /* for svg */
  position: relative;

  background: linear-gradient(
    254.34deg,
    #007cf0 17.19%,
    #ff0080 31.65%,
    #f9cb28 47.39%,
    #ff4d4d 61%,
    #7928ca 75.04%,
    #00dfd8 89.08%
  );
  background-size: 300% 300%;
  animation: gradient 50s ease infinite;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    /* set height to pixels if you want angle to change with screen width */
    height: 10vw; /* or 100px */
  }

  .hero-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    h1 {
      color: #ffffff;
      font-size: 14vw;
      line-height: 1.45;

      /* greater than 480px (small) */
      ${media.sm} {
        font-size: 8vw;
      }

      /* greater than 768px (medium) */
      ${media.md} {
        font-size: 8.5vw;
        margin-bottom: 2.9rem;
      }

      /* greater than 992px (large) */
      ${media.lg} {
        font-size: 85px;
      }

      /* less than or equal to 540px height for mobile landscape orientation */
      @media only screen and (max-height: 540px) {
        /* greater than 480px (small) */
        ${media.sm} {
          font-size: 12.5vh;
          margin-bottom: 0;
        }
      }

      background: linear-gradient(99.3deg, #ffffff 51.35%, #1f2833 80.81%);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;

      background-size: 500% 500%;
      animation: gradient 30s ease infinite;

      /* IE10+ CSS styles */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: transparent; // ToDo
      }
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`
